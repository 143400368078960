<template>
    <div class="pagination-box">
        <a-pagination
            show-quick-jumper
            v-model="cpage"
            :total="ctotal"
            :page-size="cpageSize"
            show-size-changer
            :show-total="(total) => `共${total}条`"
            show-less-items
            :page-size-options="pageSizeOptions"
            @change="onChange"
            @showSizeChange="onChange"
            size="small"
        />
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: {
        page: {
            type: Number,
            default: 1,
        },
        total: {
            type: Number,
            default: 0,
        },
        pageSizeOptions: {
            type: Array,
            default: () => ["20", "50"],
        },
        pageSize: {
            type: Number,
            default: 50,
        },
    },
    methods: {
        onChange(page, pageSize) {
            console.log(page, pageSize);

            this.cpageSize = pageSize;
            this.$emit("paginationChange", {
                //page: this.cpage,
                page: page,
                pageSize,
            });
        },
    },
    computed: {
        cpage: {
            get() {
                return this.page;
            },
            set(val) {
                this.$emit("update:page", val);
            },
        },
        ctotal: {
            get() {
                return this.total;
            },
            set(val) {
                this.$emit("update:total", val);
            },
        },
        cpageSize: {
            get() {
                return this.pageSize;
            },
            set(val) {
                this.$emit("update:pageSize", val);
            },
        },
    },
};
</script>
<style lang="less" scoped>
.pagination-box {
    // width: 100%;
    display: flex;
    justify-content: flex-end;

    /deep/ .ant-pagination {
        font-size: 12px;
    }

    //   /deep/.ant-pagination-options {
    //     font-size: 12px;
    //   }
    /deep/ .ant-select {
        font-size: 12px;
    }

    /deep/ .ant-pagination-total-text {
        color: #048fff;
    }
}
</style>
